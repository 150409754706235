<template>
  <!-- <el-dialog
    width="960px"
    :title="$t('sipServer.addServer')"
    :visible.sync="showServerAdd"
    :before-close="closeDialog"
  > -->
  <div class="page">
    <el-form class="page-main" ref="form" :model="form" :rules="rules" label-width="auto">
      <el-form-item :label="$t('sipServer.serverName')" prop="serverName">
        <el-input
          v-model="form.serverName"
          :placeholder="$t('sipServer.serverNamePla')"
          :maxlength="128"
        >
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('sipServer.sipServer')" prop="sipServer">
        <el-input
          v-model="form.sipServer"
          :placeholder="$t('sipServer.sipServerPla')"
          :maxlength="128"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('sipServer.sipServerPort')" prop="port">
        <el-input v-model.number="form.port" :maxlength="128"></el-input>
      </el-form-item>
      <el-form-item :label="$t('sipServer.registerExpireTime')" prop="registerExpireTime">
        <el-input v-model.number="form.registerExpireTime" :maxlength="128"></el-input>
      </el-form-item>
      <el-form-item :label="$t('sipServer.transportMode')" prop="transportMode">
        <el-select v-model="form.transportMode" style="width: 100%">
          <el-option
            v-for="item in transportModeDics"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('sipServer.outboundProxy')" prop="outbound">
        <el-input
          v-model="form.outbound"
          :placeholder="$t('sipServer.outboundProxyPla')"
          :maxlength="128"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('sipServer.outboundProxyPort')" prop="outboundProxyPort">
        <el-input v-model.number="form.outboundProxyPort" :maxlength="128"></el-input>
      </el-form-item>
      <el-form-item :label="$t('ssite.site')" prop="siteId">
        <select-site
          v-model="form.siteId"
          :maxWidth="maxWidth"
          defaultShow
        ></select-site>
      </el-form-item>
      <!-- 自定义字段 -->
      <el-form-item :label="$t('sipServer.additionalSettings')" prop="addtion">
        <div class="addtion-item" v-for="(item, i) in form.addtion" :key="i">
          <div class="addtion-values">
            <el-select
              v-model="item[0]"
              :placeholder="$t('sipServer.additionalSettingsPal')"
              @change="additionalChange(item)"
            >
              <el-option
                v-for="settingItem in adtSettingsDics"
                :key="settingItem.id"
                :label="settingItem.name"
                :value="settingItem.id"
                :disabled="settingItem.disabled"
              ></el-option>
            </el-select>
            <el-select v-model="item[1]" v-if="item[0] === 'Secondary Transport Mode'">
              <el-option
                v-for="item in transportModeDics"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <el-input
              v-model.number="item[1]"
              placeholder
              v-else-if="
                [
                  'Secondary SIP Server Port',
                  'Secondary Register Expire Time',
                  'Secondary Outbound Proxy Port',
                ].indexOf(item[0]) > -1
              "
            ></el-input>
            <el-input v-model="item[1]" placeholder v-else></el-input>
          </div>
          <div class="addtion-delete" @click="deleteAssignDevice(i)">
            <el-tooltip effect="dark" :content="$t('delete')" placement="top" :open-delay="1000">
              <i class="el-icon-circle-close"></i>
            </el-tooltip>
          </div>
        </div>
        <div class="addtion-add" @click="addAdditional" v-show="addAdditionalShow">
          <el-tooltip effect="dark" :content="$t('add')" placement="top" :open-delay="1000">
            <el-button
              style="width: 112px;"
              icon="el-icon-plus"
              type="primary"
            >{{ $t('add') }}</el-button>
          </el-tooltip>
        </div>
      </el-form-item>
      <el-form-item class="btns">
        <el-button type="primary" plain @click="$router.go(-1)">{{ $t('cancel') }}</el-button>
        <el-button type="primary" @click="submit('form')">{{ $t('save') }}</el-button>
      </el-form-item>
    </el-form>
  </div>
  <!-- </el-dialog> -->
</template>

<script>
import { mapGetters } from 'vuex'
import { serverQuery, serverSave } from '@/api/sipServer'
import { SelectSite } from '@/components'

export default {
  name: 'ServerDetails',
  components: {
    SelectSite,
  },
  data() {
    return {
      showServerAdd: false,
      oldsite: '',
      pageType: this.$route.query.id ? true : false,
      addAdditionalShow: true,
      form: {
        serverName: '',
        sipServer: '',
        port: 5060,
        registerExpireTime: 3600,
        transportMode: '0',
        outboundProxyPort: 5060,
        outbound: '',
        siteId: '',
        addtion: [['', '']],
      },
      rules: {
        serverName: {
          required: true,
          message: this.$t('sipServer.serverNameRule'),
          trigger: 'blur',
        },
        sipServer: {
          required: true,
          validator: (rule, value, callback) => {
            const reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
            const regO = /^(?=^.{3,255}$)[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/
            if (value === '') {
              callback(new Error(this.$t('sipServer.sipServerRule')))
            } else {
              if (!reg.test(value) && !regO.test(value)) {
                callback(new Error(this.$t('sipServer.sipServerRule')))
              } else {
                callback()
              }
            }
          },
          trigger: 'blur',
        },
        port: {
          required: true,
          type: 'number',
          min: 0,
          max: 65535,
          message: this.$t('sipServer.sipServerPortRule'),
          trigger: 'blur',
        },
        registerExpireTime: {
          required: true,
          message: this.$t('sipServer.registerExpireTimeRule'),
          trigger: 'blur',
        },
        transportMode: {
          required: true,
        },
        outbound: {
          validator: (rule, value, callback) => {
            const reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
            const regO = /^(?=^.{3,255}$)[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/
            console.log('value: ', value)
            if (value === '' || value === null) {
              callback()
            } else {
              if (!reg.test(value) && !regO.test(value)) {
                callback(new Error(this.$t('sipServer.sipServerRule')))
              } else {
                callback()
              }
            }
          }
        },
        outboundProxyPort: {
          validator: (rule, value, callback) => {
            if (this.form.outbound !== '' && value === '') {
              callback(new Error(this.$t('sipServer.outboundProxyPortRule')))
            } else {
              callback()
            }
          },
          trigger: 'blur',
        },
        siteId: {
          required: true,
          message: this.$t('sipServer.siteIdRule'),
          trigger: 'change',
        },
      },
      maxWidth: 'auto',
    }
  },
  computed: {
    ...mapGetters('dist', [
      'DNSModelDics',
      'NatTraversalDics',
      'sipServerAdditionalSettings',
      'transportModeDics',
    ]),
    adtSettingsDics() {
      return JSON.parse(JSON.stringify(this.sipServerAdditionalSettings))
    },
  },
  created() {
    this.pageType && this.getData(this.$route.query.id)
  },
  mounted() {
    this.maxWidth = this.$refs.form.$el.clientWidth - 214 - 220
  },
  methods: {
    openDialog() {
      this.showServerAdd = true
    },
    closeDialog() {
      this.showServerAdd = false
    },
    getData(id) {
      serverQuery({ id })
        .then((res) => {
          let { addtion, ...data } = res.data.result.rows[0]
          addtion = addtion ? addtion : []
          this.form = { addtion, ...data, siteId: data.site }
          this.oldsite = this.form.siteId
          this.additionalChange()
        })
        .catch(() => {})
    },
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.$route.query.id && this.oldsite && this.oldsite !== this.form.siteId) {
            this.$confirm(this.$t('sipServer.siteChangeTip'), this.$t('tip'), {
              confirmButtonText: this.$t('confirm'),
              cancelButtonText: this.$t('cancel'),
              type: 'warning',
            })
              .then(() => {
                serverSave(this.form)
                  .then(({ data }) => {
                    this.$message.success(data.message)
                    this.$router.go(-1)
                    // this.showServerAdd = false
                  })
                  .catch(() => {})
              })
              .catch(() => {
                this.form.siteId = this.oldsite
              })
          } else {
            serverSave(this.form)
              .then(({ data }) => {
                this.$message.success(data.message)
                this.$router.go(-1)
                // this.showServerAdd = false
              })
              .catch(() => {})
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    addAdditional() {
      this.form.addtion.push(['', ''])
      this.additionalChange()
      this.form.addtion.length >= this.adtSettingsDics.length && (this.addAdditionalShow = false)
    },
    deleteAssignDevice(i) {
      this.form.addtion.splice(i, 1)
      this.additionalChange()
      this.form.addtion.length <= this.adtSettingsDics.length && (this.addAdditionalShow = true)
    },
    additionalChange(item) {
      const ids = this.form.addtion.map((e) => e[0])
      this.adtSettingsDics.forEach((e) => {
        item && e.id == item[0] && (item[1] = e.defaultValue)
        e.disabled = ids.includes(e.id)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@include form-page;
.page-main {
  padding: 64px 140px 110px 74px;
}
.sip-server-add {
  vertical-align: top;
  display: inline-block;
  width: 36px;
  text-align: center;
  font-size: 24px;
}
.addtion-item {
  margin-bottom: 10px;
  .addtion-values {
    position: relative;
    vertical-align: top;
    display: inline-block;
    width: 100%;
    .el-select,
    .el-input {
      width: calc((100% - 20px) / 2);
      margin-left: 20px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}
.addtion-delete {
  position: absolute;
  right: -32px - 20px;
  cursor: pointer;
  vertical-align: top;
  display: inline-block;
  text-align: center;
  font-size: 32px;
}
.addtion-add {
  cursor: pointer;
  display: inline-block;
  font-size: 24px;
}
.btns {
  display: flex;
  justify-content: flex-end;
  .el-button {
    width: 162px;
  }
}
</style>
